import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import css from './SectionWeeklyEventActivity.module.css';
import { getWeeklyEventActivities } from '../../util/api';

import { formatDistance } from 'date-fns';
import IconInfo from '../IconInfo/IconInfo';

import { NamedLink } from '../../components';

const EventActivity = ({ activityType, createdAt }) => {
  // Show date and time of time difference with current date
  const eventDate = formatDistance(createdAt, new Date(), { addSuffix: true });

  return (
    <div className={css.eventActivity}>
      <h3 className={css.activityType}>{activityType}</h3>
      <p className={css.activityDate}>{eventDate}</p>
    </div>
  );
};

const SectionWeeklyEventActivity = () => {
  const [eventActivities, setEventActivities] = useState([]);
  const [totalEvents, setTotalEvents] = useState(0);

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted

    const fetchEventActivities = async () => {
      const response = await getWeeklyEventActivities();
      if (response && response.data && Array.isArray(response.data)) {
        const originalEventActivities = response.data;
        if (isMounted) { // Only update state if the component is still mounted
          setTotalEvents(originalEventActivities.length);

          // randomize the order
          const displayedActivities = originalEventActivities
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);

          setEventActivities(displayedActivities);
        }
      }
    };

    fetchEventActivities();

    return () => {
      isMounted = false; // Cleanup function to set isMounted to false
    };
  }, []);

  if (totalEvents === 0) {
    return null;
  }

  return (
    <section className={css.sectionEventActivity}>
      <div className={css.headerContainer}>
        <div className={css.title}>
          <h5 className={css.sectionHeading}>Weekly Event Activities</h5>
          <a href='/p/activity_ticker_page'>
            <IconInfo className={css.iconInfo} />
          </a>
        </div>
        <p className={css.totalEvents}>Total events: {totalEvents}</p>

      </div>
      <div className={css.eventActivities}>
        <Marquee autoFill>
          {eventActivities.map(({ activityType, createdAt }, index) => (
            <EventActivity activityType={activityType} createdAt={createdAt} key={index} />
          ))}
        </Marquee>
      </div>
    </section>
  );
};

export default SectionWeeklyEventActivity;
