import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconInfo.module.css';

const IconInfo = props => {
  const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);
    
  return (
    <svg
      className={classes}
      width="16"
      height="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <title>background</title>
        <rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1" />
      </g>
      <g>
        <title>Layer 1</title>
        <path
          fill="#ffffff"
          id="svg_1"
          d="m8,16a8,8 0 1 0 0,-16a8,8 0 0 0 0,16zm0.93,-9.412l-1,4.705c-0.07,0.34 0.029,0.533 0.304,0.533c0.194,0 0.487,-0.07 0.686,-0.246l-0.088,0.416c-0.287,0.346 -0.92,0.598 -1.465,0.598c-0.703,0 -1.002,-0.422 -0.808,-1.319l0.738,-3.468c0.064,-0.293 0.006,-0.399 -0.287,-0.47l-0.451,-0.081l0.082,-0.381l2.29,-0.287l-0.001,0zm-0.931,-1.088a1,1 0 1 1 0,-2a1,1 0 0 1 0,2z"
        />
      </g>
    </svg>
  );
};

IconInfo.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconInfo.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconInfo;
